<template>
  <div>
    <div>
      <button
        v-for="action in actions"
        :key="action.Title"
        :class="`btn btn-${
          action.ButtonVariant ? action.ButtonVariant : 'primary'
        }`"
        @click="processAction(action)"
      >
        {{ action.Title }}
      </button>
    </div>
  </div>
</template>

<script>
import processLegacyEvent from "../../../src/actions/processLegacyEvent";

export default {
  name: "DialogContinueTo",
  props: {
    dialogData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    actions() {
      return this.dialogData?.Actions;
    },
  },
  methods: {
    async processAction(action) {
      if (action.LegacyAction) {
        processLegacyEvent(
          action.LegacyAction,
          this.$store.getters.subWindow.id,
        );
        this.hideDialog();
        return;
      }

      if (action.GlobalActions && action.GlobalActions.length) {
        for (const globalAction of action.GlobalActions) {
          const importedModule = await import(
            `@/actions/${globalAction.Function}.js`
          );

          importedModule.default(globalAction.Data);
        }
      }

      if (action.ComponentActions?.length) {
        for (const componentAction of action.ComponentActions) {
          await this.$store.dispatch(`dialog/${componentAction.Function}`, {
            data: componentAction.Data,
          });
        }
      }

      //this.hideDialog();
    },
    hideDialog() {
      this.$store.commit("dialog/hideDialog");
    },
  },
};
</script>
